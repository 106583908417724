<template>
  <div class="content-btns-actions-report-all">
    <div class="content-btn-generate-report">
      <v-btn @click="setDialogGenerateReport" class="global-btn-primary">
        Generar reporte
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    setDialogGenerateReport() {
      this.$emit("setDialogGenerateReport");
    },
  },
};
</script>
<style scoped>
.content-btns-actions-report-all {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  margin-bottom: 25px;
}
.content-btn-generate-report {
  width: 200px;
}

/*Plantilla media query css responsive*/

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-actions-report-all {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .content-btn-generate-report {
    width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
